import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Main from './Main'
import Form from './Form.js'
import Form2 from './Form2.js'
import Form3 from './Form3.js';




export default function Route(){
	const [params, setParams] = useState(null);
	const location = useLocation();
	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const singleValue = queryParams.get('org');
		if (!singleValue) return
		setParams(singleValue);
		
	}, []);
	
	return (
	
    <>
    {(params == "UCG") ?  <Form/> :  (params == "OAHG") ? <Form2/> : (params =="OAHG_") ? <Form3/> : <Main/>}
	</>
	)
}