
import { useState,useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Col, Form, Row, Button, Modal } from "react-bootstrap";
import db from './db.js'



function Main() {
    
    const [error, setError] = useState("");
    const [show, setShow] = useState(false);
    const [user, setUser] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const checkEmail = event => {
        setEmail(event.target.value);
    }

    const checkPassword = event => {
         setPassword(event.target.value);
         
    }
    const submit = async () => {
        console.log("submit");
        const user = await db.loginUser("login", email, password);
        if (user.user == "0"){
            setError("Wrong Email or Password!")
        }  else{
            setUser("1")
        } 
        
        
        
    }


    return (
        <>
        
        <div className="bg-primary text-center">
            <h2 className="text-center text-white pt-4 fs-1 block-example border-bottom border-warning border-3 pb-4 mx-5">Forms Builder</h2>
            {/* <img src="logo-fb.jpg" className="m-3 border border-light" /> */}
            <img src="logo_FB.jpg" className="m-3 " style={{width:"60px"}} />
            <h4 className="text-center text-white pb-4 mt-4 ">Manage Your Data with Smart and Powerful Forms</h4>
        </div>
            <div>
                <Row className="mt-5">
                    <Col></Col>
                    <Col xs={8} md={2} >
                        <Form >
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label className="text-primary">Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" onBlur={checkEmail} />

                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label className="text-primary">Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" onBlur={checkPassword}/>
                            </Form.Group>
                            <div className="text-center pb-5">
                                <Button variant="primary" onClick={submit}>Submit</Button>
                            </div>
                            <p className="text-danger fs-5 text-center">{error}</p>
                            <div className="text-center mt-5">
                                <Button variant="outline-primary" onClick={handleShow}>Sign up</Button>
                                <Modal show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Oops...</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>There currently seems to be a problem with signing up!</Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>

                                    </Modal.Footer>
                                </Modal>

                            </div>
                        </Form>
                    </Col>
                    <Col></Col>
                </Row>

            </div>
        </>
    );
}

export default Main;
