import React from "react";
import ReactDOM from "react-dom";
import {  BrowserRouter as Router } from "react-router-dom";
import Route from './Route.js';

function App(){
	return (
	<>
	  <Router>
	    <Route/>
	  </Router>
	</>

)}
export default App;
