import { BrowserRouter, Switch, Route, useParams } from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { useEffect, useState } from "react";
import { Col, Modal, Row, Button, Spinner } from 'react-bootstrap';

import './Form2.css';


import db from './db';

function Form3() {

    const [inputs, setInputs] = useState({});
    const pre = "9725";
    const [error, setError] = useState("");
    const [error2, setError2] = useState("");
    const [error3, setError3] = useState("");
    const [submitError, setSubmitError] = useState('** يجب تعبئة جميع الحقول **');
    const [status, setStatus] = useState(0);
    const [formNumber, setFormNumber] = useState("");

    const [files, setFiles] = useState([])
    
    const [previewUrls, setPreviewUrls] = useState([]);

    const handleFileChange = (event) => {
        setFiles ([...event.target.files])

        const urls = files.map(file => URL.createObjectURL(file));
        setPreviewUrls(urls);
    }
    
    useEffect(() => {
        if (files.length > 0) {
            const urls = files.map(file => URL.createObjectURL(file));
            setPreviewUrls(urls);

            // Cleanup: Revoke object URLs to avoid memory leaks
            return () => {
                urls.forEach(url => URL.revokeObjectURL(url));
            };
        }
    }, [files]);

    useEffect(() => {
        setFormNumber(Math.floor(Math.random() * 100000)+100000)
      }, []);

    useEffect(() => {
        fetch('https://www.forms-builder.com/app/');
      }, []);
      
    const handleChange = (event) => {
        
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
        if (name == 'phone') {
            setError("");
            setError2("")
        } else if (name == 'email') {
            setError3('')
        }
        if (!(inputs.username && inputs.idNumber && inputs.address && files)) {
            setSubmitError('** يجب تعبئة جميع الحقول **')
        }else if(value==''){
            setSubmitError('** يجب تعبئة جميع الحقول **')
        } else {
            setSubmitError('')
        }
    }
    
    const handleSubmit = async () => {

        if (error === '' && error2 === '' && error3 === '' && submitError == '' ) {
        setStatus(10);
        const formData = new FormData();
        formData.append('form', "OAHG2");
        formData.append('username', inputs.username);
        formData.append('idNumber', inputs.idNumber);
        formData.append('phone', '');
        formData.append('email', '');
        formData.append('address', inputs.address);
        formData.append('relation', '');
        files.forEach((file, index) => {
            formData.append(`file${index}`, file);
        });

        try {
            const response = await fetch('https://www.forms-builder.com/app/', {
                method: 'POST',
                body: formData,
            });
            const result = await response.json();
            console.log(result);
        } catch (error) {
            console.error('Error:', error);
        
        };
            myFunction();
            setSubmitError('** يجب تعبئة جميع الحقول **')
        }
    }


    const phoneValidation = () => {
        const arabDigits=['٠','١' ,'٢' , '٣' ,'٤' , '٥' ,'٦' , '٧' ,'٨' , '٩'];
        for (let i of arabDigits){
            try {
                if (inputs.phone.includes(i)){
                    //
                    if (inputs.phone.length > 15) {
                        
                        setError2("** رقم الهاتف غير صالح **");
                        return}
                    else {
                        return
                    }
                }    
            } catch (error) {
                setError2("** رقم الهاتف غير صالح **");     
            }
            
        
        }
        if (isNaN(inputs.phone)) {
            setError("** الرجاء إدخال أرقام فقط **");
            return
        } else if (inputs.phone.length > 15) {
            setError2("** رقم الهاتف غير صالح **");
            return
        } 
    }
    

    const emailValidation = () => {

        try {
            if (!(inputs.email.includes('@'))||inputs.email == '' ) {
            setError3("** البريد الالكتروني غير صالح **")
            }
            
        } catch (error) {
            setError3("** البريد الالكتروني غير صالح **")
        }
        
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let myVar;
    const myFunction = () => {
        setStatus(7);
        myVar = setTimeout(showPage, 1000);
    }
    const showPage = () => {
        setStatus(20);
        myVar = setTimeout(showPage2, 1000)
    }
    const showPage2 = () => {
        setStatus(30);
        myVar = setTimeout(showPage3, 1000)
    }
    const showPage3 = () => {
        setStatus(52)
        myVar = setTimeout(showPage4, 1000)
    }
    const showPage4 = () => {
        setStatus(73)
        myVar = setTimeout(showPage5, 2000)
    }
    const showPage5 = () => {
        setStatus(77)
        myVar = setTimeout(showPage6, 2000)
    }
    const showPage6 = () => {
        setStatus(95)
        myVar = setTimeout(showPage7, 2000)
    }
    const showPage7 = () => {
        setStatus(98)
        myVar = setTimeout(showPage8, 1000)
    }
    const showPage8 = () => {
        setStatus(99)
        myVar = setTimeout(showPage9, 1000)
    }
    const showPage9 = () => {
        setStatus(100);
        setStatus(0);

        inputs.username = '';
        inputs.idNumber = '';
        inputs.phone = '';
        inputs.email = '';
        inputs.address = '';
        inputs.relation = '';
        setFiles([]);
        setPreviewUrls([]);

        handleShow();
    }
    document.body.style = 'background: rgb(152, 164, 137);'
    return (
        <div className='container'>
        
        <div  dir="rtl" lang="ar" className="mt-2">
            
            <div className="text-center mb-3">
                
                
                {/* <span className='unwan1' style={{color:"blue", fontSize:"1.5em"}}>L'Organisation </span>
                <span className='unwan1' style={{color:"white", fontSize:"1.5em"}}>d'Aide Humanitaire </span>
                <span className='unwan1' style={{color:"red", fontSize:"1.5em"}}> à Gaza </span><br/> */}
                <img src="OAHG_logo.png" className="m-3" style={{width:"120px"}}/>
                <h1 className='unwan1' >L'Organisation d'Aide Humanitaire à Gaza</h1>
                </div>
            <Row>
                <Col></Col>
                <Col>
                    {status == 0 ?
                        <>
                            {/* <h3 className="mt-1 unwan">الرجاء تعبئة التفاصيل وسوف نتواصل معكم بأقرب وقت  </h3> */}
                        <div style={{position:"relative", left:"-10%"}}>
                            <form dir="rtl" lang="ar" className="mt-3" autoComplete='off' >
                                <label >
                                    <h6 className=" p-2" style={{ width: "150px" }}>الاسم الكامل:</h6>
                                    <input style={{ width: "330px", marginRight:"10px" }}
                                        type="text"
                                        name="username"
                                        value={inputs.username || ""}
                                        onChange={handleChange}
                                    />
                                </label><br />
                                <label >
                                    <h6 className=" p-2 mt-3" style={{ width: "160px" }}>رقم الهوية:</h6>
                                    <input style={{ width: "330px", marginRight:"10px" }}
                                        type="text"
                                        name="idNumber"
                                        value={inputs.idNumber || ""}
                                        onChange={handleChange}
                                    />
                                </label><br />
                                
                                <label style={{ width: "500px" }}>
                                    <h6 className="d-inline-block p-2 mt-3" style={{ width: "160px" }}>تفاصيل الطلب:</h6><br/>
                                    <textarea style={{width:330, marginRight:"10px"}}
                                        type="textarea"
                                        rows={2}
                                        name="address"
                                        value={inputs.address || ""}
                                        onChange={handleChange}
                                    />
                              </label><br />
                              <label >
                                    <h6 className=" p-2 mt-3" style={{ width: "160px" }}>تحميل الملفات:</h6>
                                    </label>
                              <div className='text-center my-3'>
                                <input type="file" multiple onChange={handleFileChange} accept=".jpeg, .jpg, .png, .gif"/>
                                </div>  
                                
                                {submitError != '' ? <p className='text-center' style={{ color: "red", marginLeft:"30%"}}>{submitError}</p> : ""}
                                


                            </form>
                            <div>
                {previewUrls.map((url, index) => (
                    <img key={index} src={url} alt={`Preview ${index}`} style={{ width: '100px', height: '100px', objectFit: 'cover', margin: '5px' }} />
                ))}
            </div>
                            
                        </div>
                            <div  className="text-center m-3" ><Button variant="dark" onClick={handleSubmit} >ارسال</Button></div>

                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>

                                </Modal.Header>
                                <Modal.Body className="text-center">تم ارسال البيانات </Modal.Body>
                                <Modal.Footer>

                                </Modal.Footer>
                            </Modal>
                        </>
                        : <div className="my-5 text-center" style={{ height: "600px" }}><Spinner animation="border" variant="primary" size='lg' style={{width:"30px",height:"30px"}}></Spinner></div>}
                </Col>
                <Col></Col>
            </Row>

        </div>
        
        </div>
    );
}

export default Form3;
