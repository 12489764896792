
const addForm = async (form, username, idNumber, phone, email, address, relation, formNumber ) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({form, username, idNumber, phone, email, address, relation, formNumber})
        
    }
    
    const response = await fetch('https://www.forms-builder.com/app/', requestOptions);
    console.log(response);
    const added = await response.json();
    console.log(added);
    return added ;
    
}


const loginUser = async (form, userEmail, password) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({form, userEmail, password})
        
    }
    
    const response = await fetch('https://www.forms-builder.com/app/', requestOptions);
    console.log(response);
    const added = await response.json();
    console.log(added);
    return added ;
    
}




    
export default {
    
addForm,
loginUser


}
